@import "~react-image-gallery/styles/css/image-gallery.css";

.Photos {
    width: 100%;
    height: fit-content;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
}

#photosContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: url(../Media/leaves-background-with-metallic-foil/3915228.jpg) no-repeat top center;
    background-size: 100% 100%; 
}

#photosMainDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f7ed7f52;
    padding: 0% 0% 4%;
}

.photosHeader {
    width: 100%;
    height: fit-content;
    padding: 2% 0% 2%;
}

#photosFlowerDivider {
    width: 50%;
    height: 8vh;
}

#photosGalleryDiv {
    max-width: 100%;
    height: 100%;
}

.image-gallery {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 400px) {

    #photosFlowerDivider {
        width: 80%;
        height: 8vh;
    }
}