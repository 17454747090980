.Nav {
    width: 100% !important;
    height: 15%;
    background-color: none;
    font-size: calc(10px + 2vmin);
}

#navBrand {
    font-size: 1.5em;
}

.navPic {
    width: 10vh;
    height: 10vh;
}

.navbar-nav {
    width: 100%;
    height: 100%;
}

.navbar-collapse, .navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-family: 'PT+Mono', monospace;
    font-size: 0.7em;
}

.nav-link {
    margin: 0% 2%;
    color: #020b30 !important;
    font-size: 1.4em;
}

.nav-link:hover {
    background-color: #cfeec76e;
    color: #18191f;
    border-bottom: 0.09em solid #31333B;
    border-top: 0.09em solid #31333B;
}

.nav-link.active {
    border-bottom: 0.09em solid #31333B;
}

@media only screen and (max-width: 1201px) {
    .navbar-collapse, .navbar-nav {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-family: 'PT+Mono', monospace;
        font-size: 0.7em;
        background-color: #e2e8ff;
    }

    .nav-link {
        width: 90%;
        color: #020b30;
        margin: 1% 2%;
    }
}

@media only screen and (max-width: 769px) {
    .nav-link {
        width: 90% !important;
        margin: 1% 2%;
        font-size: 2.2em;
        color: #020b30;
    }
}