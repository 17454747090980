.custom-footer-bg {
    width: 100%;
    background-color: #ffffff;
    font-family: "Baskervville", sans-serif;
    border-top: #31333B solid 0.08em;
}

.footer-title {
    color: #31333B !important;
    font-family: 'Courgette', sans-serif;
    font-size: x-large;
    margin: 0% 2% 0% 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footerLogo {
    width: 6em;
    height: 4em;
    margin: 0% 3% 0% 0%;
    border-radius: 10%;
}

.footerLinks {
    width: 80%;
    margin: 0% 1%;
    height: 100%;
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: #ffffff;
}

.footer-icon-fb, .footer-icon-email, .footer-icon-attributions {
    height: 2.3em;
    width: 2.3em;
    color: #31333B;
}

.footer-link-fb, .footer-link-email, .footer-link-attributions {
    width: 20% !important;
}

@media only screen and (max-width: 934px) {
    .footer-title {
        width: fit-content;
    }

    .footerLogo {
        width: 6em;
        height: 4em;
    }

    .footerLinks {
        width: 75%;
    }
}

@media only screen and (max-width: 730px) {
    .footerLogo {
        width: 5em;
        height: 4em;
    }

    .footerLinks {
        width: 75%;
    }

    .footer-icon-fb, .footer-icon-email, .footer-icon-attributions {
        height: 2.1em;
        width: 2.1em;
    }
}

@media only screen and (max-width: 610px) {
    .footerLogo {
        width: 4em;
        height: 3em;
    }

    .footerLinks {
        width: 71%;
    }

    .footer-icon-fb, .footer-icon-email, .footer-icon-attributions {
        height: 2em;
        width: 2em;
    }
}

@media only screen and (max-width: 420px) {
    .footerLogo {
        width: 3.2em;
        height: 2.2em;
    }

    .footerLinks {
        width: 68%;
    }
    
    .footer-icon-fb, .footer-icon-email, .footer-icon-attributions {
        height: 1.8em;
        width: 1.8em;
    }
}