.About {
    width: 100%;
    height: fit-content;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
}

#aboutContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0% 0% 4%;
}

#aboutHeader {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: url(../Media/hand-painted-watercolor-nature-background-forest/5238188.jpg) no-repeat left center;
    background-size: 100% 100%; 
    padding: 2% 0% 0%;
}

#aboutTitle {
    font-family: 'Caveat', sans-serif;
    font-size: 2em;
    font-weight: bold;
}

#aboutFlowerDivider, .aboutParagraphDivider {
    width: 40%;
    height: 8vh;
}

#aboutProfilePicDiv {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1% 1% 4%;
}

#aboutProfilePic {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    border: solid #ffffff 0.2em;
}

#aboutProfileTextDiv {
    /* width: 90%; */
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    /* background-color: #c6ebbcfa;
    border: groove 0.4em #8aa05f; */
    padding: 0% 2% 0%;
}

.aboutParagraphTitle {
    font-size: 1.5em;
    padding: 2% 2%;
    width: 100%;
}

.aboutProfileParagraph {
    font-family: 'PT+Mono', monospace;
    font-size: 1em;
    padding: 0% 4% 3%;
    width: 100%;
}

#paragraph0 {
    background-color: #293b33;
    color: #ffffff;
    text-align: left;
}

#paragraph1 {
    text-align: left;
}

#paragraph2 {
    background-color: #586e78;
    color: #ffffff;
    text-align: left;
}

@media only screen and (max-width: 430px) {
    #aboutFlowerDivider {
        width: 80%;
    }

    #aboutProfilePic {
        width: 70%;
        height: 80%;
        border-radius: 50%;
        border: solid #ffffff 0.2em;
    }

    .aboutParagraphTitle {
        font-size: 1.6em;
    }
    
    .aboutProfileParagraph {
        font-size: 1.1em;
    }
}