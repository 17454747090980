.Programming {
    width: 100%;
    height: fit-content;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
}

#programmingBg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: url(../Media/top-border-vines-transparent.gif) no-repeat top center;
    background-size: 100% 15%;
    padding: 0% 0% 2%;
}

#programmingHeader {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 8% 0% 4%;
}

#programmingTitle {
    font-family: 'Caveat', sans-serif;
    font-size: 2em;
    font-weight: bold;
}

#programmingFlowerDivider {
    width: 50%;
    height: 8vh;
}

.programmingParagraphDivider {
    width: 50%;
    height: 8vh;
}

#programmingMainInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.programmingMainParagraph {
    width: 70%;
    font-size: 1.2em;
}

#programmingCards {
    width: 100%;
    margin: 0% 0% 0%;
    padding: 0% 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(../Media/hand-painted-watercolor-nature-background/5333978.jpg) no-repeat top center;
    background-size: 100% 100%;
}

#programmingLastInfo {
    width: 100%;
    padding: 3%;
    font-size: 1.2em;
}

@media only screen and (max-width: 700px) {
    #programmingHeader {
        padding: 18% 0% 4%;
    }
}