.ProgramCard {
    width: 100%;
    height: fit-content;
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#bullfrogCard, #woodpeckerCard, #mushroomCard {
    background-color: #95AC68;
}

#butterflyCard, #squirrelCard, #hikingCard {
    background-color: #95ac6871;
}

.programCardIconDiv {
    width: 35%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.programCardIcon {
    width: 60%;
    height: 100%;
    margin: 0% 2% 0% 0%;
}

.programCardInfo {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#woodpeckerIcon {
    width: 40%;
    height: 30vh;
}

#squirrelIcon {
    width: 50%;
    height: 30vh;
}

#mushroomIcon {
    width: 50%;
    height: 30vh;
}

#hikingIcon {
    width: 70%;
}

.programCardTitle {
    font-size: 1.35em;
    font-weight: bold;
    text-align: left;
}

.programCardDetails, .programCardNotes {
    font-family: 'PT+Mono', monospace;
}

.programCardDetails {
    font-size: 0.85em;
    text-align: left;
}

.programCardNotes {
    font-size: 0.75em;
    padding: 5% 0% 0%;
    text-align: left;
}

@media only screen and (max-width: 770px) {
    .ProgramCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .programCardIconDiv {
        margin: 2% 0%;
    }
    #woodpeckerIcon {
        width: 40%;
        height: 25vh;
    }
    #squirrelIcon {
        width: 50%;
        height: 25vh;
    }
    .programCardInfo {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (max-width: 700px) {
    .ProgramCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .programCardIconDiv {
        margin: 2% 0%;
        width: 50%;
    }
}