.ImageCard {
    width: 100%;
    height: fit-content;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: #31333B solid 0.07em;
    margin: 1% 0%;
}

.imageCardImageDiv {
    width: 60%;
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imageCardImage {
    width: 60%;
    height: 100%;
    margin: 0% 2% 0% 0%;
}

@media only screen and (max-width: 770px) {
    .imageCardImageDiv {
        width: 80%;
        height: 75%;
    }
}